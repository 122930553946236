import { useEffect, lazy, Suspense } from 'react'
import { Lottie } from '@crello/react-lottie';
import { Route, Routes, Navigate, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { ApolloProvider, ApolloClient, InMemoryCache, from } from '@apollo/client';
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { setContext } from '@apollo/client/link/context';
import { offsetLimitPagination } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// Custom Hooks
import useHandleCache from "./hooks/useHandleCache";

// Custom Component
import ReactiveWelcomeModal from './components/common/modal/authentication/reactivewelcome';
import RecoveryCodeModal from './components/common/modal/user/recoverycode';
import RecoveryAlertModal from './components/common/modal/authentication/recovery/alert';

// Scroll Component
import ScrollToTop from './components/common/scrolltotop';

// Auth-Service
import PrivateRoute from './utils/privateroute';

// Utility Service
import { lazyRetry } from './utils';
import { removeUserSession } from './utils/auth';

// Settings
import { animationOptions } from './settings';

// Redux-Functions
import { setUser, setToken, setSignIn, setReactivateWelcomeModal, setSignInModal, selectToken, selectTheme, selectReactivateWelcomeModal, selectRecoveryCodeModal, selectRecoveryAlertModal } from "./redux/userSlice";

// Public Page
const Register = lazy(() => lazyRetry(() => import('./pages/authentication/register')));
const Dashboard = lazy(() => lazyRetry(() => import('./pages/dashboard')));
const AboutUs = lazy(() => lazyRetry(() => import('./pages/content/aboutus')));
const ContactUs = lazy(() => lazyRetry(() => import('./pages/content/contactus')));
const PrivacyPolicy = lazy(() => lazyRetry(() => import('./pages/content/privacypolicy')));
const TermsAndConditions = lazy(() => lazyRetry(() => import('./pages/content/termsandconditions')));
const Sitemap = lazy(() => lazyRetry(() => import('./pages/content/sitemap')));
const Premium = lazy(() => lazyRetry(() => import('./pages/premium')));
const PremiumCustomization = lazy(() => lazyRetry(() => import('./pages/premium/customization')));
const FourZeroFour = lazy(() => lazyRetry(() => import('./pages/error/404')));
const Brand = lazy(() => lazyRetry(() => import('./pages/brand')));
const AddBrand = lazy(() => lazyRetry(() => import('./pages/brand/addbrand')));
const Product = lazy(() => lazyRetry(() => import('./pages/product')));
const AddProduct = lazy(() => lazyRetry(() => import('./pages/product/add')));
const UpdateProduct = lazy(() => lazyRetry(() => import('./pages/product/update')));
const ProductComparison = lazy(() => lazyRetry(() => import('./pages/product/comparison')));
const Explore = lazy(() => lazyRetry(() => import('./pages/explore')));
const Search = lazy(() => lazyRetry(() => import('./pages/search')));
const User = lazy(() => lazyRetry(() => import('./pages/user')));
const UserSettings = lazy(() => lazyRetry(() => import('./pages/user/settings')));
const UserLogout = lazy(() => lazyRetry(() => import('./pages/user/logout')));
const Industry = lazy(() => lazyRetry(() => import('./pages/industry')));
const SubIndustry = lazy(() => lazyRetry(() => import('./pages/subindustry')));
const Category = lazy(() => lazyRetry(() => import('./pages/category')));
const SubCategory = lazy(() => lazyRetry(() => import('./pages/subcategory')));
const PostDetails = lazy(() => lazyRetry(() => import('./pages/postdetails')));
const IgniteOverTime = lazy(() => lazyRetry(() => import('./pages/igniteovertime')));
const AllIndustryIgnites = lazy(() => lazyRetry(() => import('./pages/allindustryignites')));

const App = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const theme = useSelector(selectTheme);
    const reactivateWelcomeModal = useSelector(selectReactivateWelcomeModal);
    const recoveryCodeModal = useSelector(selectRecoveryCodeModal);
    const recoveryAlertModal = useSelector(selectRecoveryAlertModal);

    const options = animationOptions(theme);

    const { isLoading, isLatestVersionAvailable } = useHandleCache();

    moment.suppressDeprecationWarnings = true;

    useEffect(() => {
        if (document.getElementsByTagName("body") && theme === 'dark') {
            document.getElementsByTagName("body")[0].setAttribute("data-bs-theme", "dark")
        } else if (document.getElementsByTagName("body") && theme === 'light') {
            document.getElementsByTagName("body")[0].setAttribute("data-bs-theme", "light")
        }
    }, [theme])

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then((registration) => {
                registration.update();
            });
        }
    }, [location])

    useEffect(() => {
        if (searchParams?.get("login") === "true") {
            handleLogin()
        }
        // eslint-disable-next-line
    }, [searchParams, location])

    const authLink = setContext((request, previousContext) => {
        if (token) {
            return {
                headers: {
                    ...previousContext.headers,
                    Authorization: `Bearer ${token}`,
                    'apollo-require-preflight': 'true'
                }
            };
        } else {
            return {
                headers: {
                    ...previousContext.headers,
                    'apollo-require-preflight': 'true'
                }
            };
        }
    })

    const uploadLink = createUploadLink({
        uri: process.env.REACT_APP_API_URL
    });

    const errorLink = onError(({ graphQLErrors }) => {
        if (graphQLErrors?.length > 0 && token) {
            if (graphQLErrors?.some(item => item?.message === 'Invalid token')) {
                handleLogout()
            }
        }
    })

    const cache = new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    getPostsByUserId: offsetLimitPagination(),
                    getPostByBrandId: offsetLimitPagination(),
                    getPostByBrandIdNonAuth: offsetLimitPagination(),
                    getIgniteBrandsByUserId: offsetLimitPagination()
                }
            }
        }
    })

    const client = new ApolloClient({
        link: from([errorLink, authLink.concat(uploadLink)]),
        cache,
        connectToDevTools: true
    });

    /**
        * @function handleLogin
        * @params
        * @description used to open login modal
    */
    const handleLogin = async () => {
        client?.clearStore()
        removeUserSession()
        dispatch(setUser())
        dispatch(setToken())
        dispatch(setSignIn(false))
        dispatch(setReactivateWelcomeModal(false))
        dispatch(setSignInModal(true));
        navigate('/')
    }

    /**
        * @function handleLogout
        * @params
        * @description used to logout the user
    */
    const handleLogout = async () => {
        client.clearStore()
        removeUserSession()
        dispatch(setUser())
        dispatch(setToken())
        dispatch(setSignIn(false))
        dispatch(setReactivateWelcomeModal(false))
        navigate('/')
    }

    if (!isLoading && isLatestVersionAvailable) {
        return <ApolloProvider client={client}>
            <ScrollToTop />
            <Suspense fallback={<>
                <div className='d-flex justify-content-center align-items-center overflow-hidden vw-100 vh-100'>
                    <Lottie config={options} height={350} width={350} />
                </div>
            </>}>
                <Routes>
                    {/* Dashboard Page Route */}
                    <Route
                        path="/"
                        element={
                            <Dashboard />
                        }
                    />

                    {/* Authentication Route */}
                    <Route
                        path="/register"
                        element={
                            <Register />
                        }
                    />

                    {/* About Us Page Route */}
                    <Route
                        path="/about-us"
                        element={
                            <AboutUs />
                        }
                    />
                    {/* Contact Us Page Route */}
                    <Route
                        path="/contact-us"
                        element={
                            <ContactUs />
                        }
                    />
                    {/* Privacy Policy Page Route */}
                    <Route
                        path="/privacy-policy"
                        element={
                            <PrivacyPolicy />
                        }
                    />
                    {/* Terms & Conditions Page Route */}
                    <Route
                        path="/terms-and-conditions"
                        element={
                            <TermsAndConditions />
                        }
                    />

                    {/* Premium Page Route */}
                    <Route
                        path="/premium"
                        element={
                            <Premium />
                        }
                    />

                    {/* Sitemap Page Route */}
                    <Route
                        path="/sitemap"
                        element={
                            <Sitemap />
                        }
                    />

                    {/* Brand Page Route */}
                    <Route
                        path="/brand/:slug"
                        element={
                            <Brand />
                        }
                    />

                    {/* Product Page Route */}
                    <Route
                        path="/product/:slug"
                        element={
                            <Product />
                        }
                    />

                    {/* Industry Page Route */}
                    <Route
                        path="/industry/:slug"
                        element={
                            <Industry />
                        }
                    />
                    {/* Sub Industry Page Route */}
                    <Route
                        path="/subindustry/:slug"
                        element={
                            <SubIndustry />
                        }
                    />

                    {/* Category Page Route */}
                    <Route
                        path="/category/:slug"
                        element={
                            <Category />
                        }
                    />
                    {/* Sub Category Page Route */}
                    <Route
                        path="/subcategory/:slug"
                        element={
                            <SubCategory />
                        }
                    />

                    {/* Private Route */}
                    <Route
                        element={
                            <PrivateRoute
                                client={client}
                            />
                        }
                    >
                        {/* Explore Page Route */}
                        <Route
                            path="/explore"
                            element={
                                <Explore />
                            }
                        />

                        {/* User Page Route */}
                        <Route
                            path="/user/:slug"
                            element={
                                <User />
                            }
                        />
                        {/* User Settings Page Route */}
                        <Route
                            path="/settings"
                            element={
                                <UserSettings />
                            }
                        />
                        {/* User Logout Page Route */}
                        <Route
                            path="/user/logout"
                            element={
                                <UserLogout />
                            }
                        />

                        {/* Add Brand Page Route */}
                        <Route
                            path="/add-brand"
                            element={
                                <AddBrand />
                            }
                        />

                        {/* Add Product Page Route */}
                        <Route
                            path="/add-product"
                            element={
                                <AddProduct />
                            }
                        />
                        {/* Update Product Page Route */}
                        <Route
                            path="/update-product"
                            element={
                                <UpdateProduct />
                            }
                        />
                        {/* Product Comparison Page Route */}
                        <Route
                            path="/product-comparison"
                            element={
                                <ProductComparison />
                            }
                        />

                        {/* Premium Customization Page Route */}
                        <Route path="/customize"
                            element={
                                <PremiumCustomization />
                            }
                        />

                        {/* All Industry Ignites Page Route */}
                        <Route
                            path="/all-industry-ignites"
                            element={
                                <AllIndustryIgnites />
                            }
                        />
                        {/* Ignite Over Time Page Route */}
                        <Route
                            path="/ignite-over-time"
                            element={
                                <IgniteOverTime />
                            }
                        />
                    </Route>

                    {/* Search Page Route */}
                    <Route
                        path="/search/:result"
                        element={
                            <Search />
                        }
                    />

                    {/* Post Details Page Route */}
                    <Route
                        path="/post/:postId"
                        element={
                            <PostDetails />
                        }
                    />
                    <Route
                        path="/post/:shareHash/:shareBySlug"
                        element={
                            <PostDetails />
                        }
                    />

                    {/* 404 Page Route */}
                    <Route
                        path="/404"
                        element={
                            <FourZeroFour />
                        }
                    />
                    {/* Error Page Route */}
                    <Route
                        path="*"
                        element={
                            <Navigate
                                to="/404"
                                replace
                            />
                        }
                    />
                </Routes>
            </Suspense>

            {/* Reactive Welcome Modal */}
            <ReactiveWelcomeModal
                show={reactivateWelcomeModal}
            />

            {/* Recovery Code Modal */}
            <RecoveryCodeModal
                show={recoveryCodeModal}
            />

            {/* Recovery Alert Modal */}
            <RecoveryAlertModal
                show={recoveryAlertModal}
            />
        </ApolloProvider>
    }
}

export default App;
