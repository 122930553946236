const Explore = (props) => {
    return <svg className={props?.className} xmlns="http://www.w3.org/2000/svg" width={props?.size} height={props?.size} viewBox="0 0 22 22" fill="none">
        <circle cx="11.0629" cy="10.8558" r="2.53943" fill="currentColor" />
        <path d="M7.79263 5.22168C5.59839 6.24574 2.50338 11.8036 7.79263 16.7787C5.90313 11.282 6.37944 8.85508 7.79263 5.22168Z" fill="currentColor" />
        <path d="M4.78453 2.80469C1.44364 4.41259 -2.01048 12.6307 4.78453 19.1952C1.74111 12.6307 2.45573 7.27106 4.78453 2.80469Z" fill="currentColor" />
        <path d="M17.2155 2.80469C20.5564 4.41259 24.0105 12.6307 17.2155 19.1952C20.2589 12.6307 19.5443 7.27106 17.2155 2.80469Z" fill="currentColor" />
        <path d="M14.3278 5.22168C16.5221 6.24574 19.6731 11.849 14.3278 16.7787C16.406 11.3954 15.9297 8.56022 14.3278 5.22168Z" fill="currentColor" />
    </svg>
}

export default Explore;