import { Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from "prop-types";

// Utility Service
import { removeUserSession } from "./auth";

// Redux-Functions
import { setUser, setToken, setSignIn, setReactivateWelcomeModal, setSignInModal, selectToken } from "../redux/userSlice";

// handle the private routes
const PrivateRoute = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(selectToken);

    if (token) {
        return <Outlet>
            {props?.children}
        </Outlet>;
    } else {
        props?.client?.clearStore()
        removeUserSession()
        dispatch(setUser())
        dispatch(setToken())
        dispatch(setSignIn(false))
        dispatch(setReactivateWelcomeModal(false))
        dispatch(setSignInModal(true));
        return <Navigate
            to="/"
        />;
    }
}

export default PrivateRoute;

PrivateRoute.prototypes = {
    children: PropTypes.node.isRequired
}