const Industry = (props) => {
    return <svg className={props?.className} xmlns="http://www.w3.org/2000/svg" width={props?.size} height={props?.size} viewBox="0 0 22 22" fill="none">
        <path d="M5.88926 6.47572L2.05078 9.5517V19.6319H19.7378V2.36152C19.7378 2.19584 19.6035 2.06152 19.4378 2.06152H15.4877C15.3221 2.06152 15.1877 2.19584 15.1877 2.36152V6.61164L10.3814 9.09931V6.47572L5.88926 9.22503V6.47572Z" stroke="currentColor" stroke-width="1.3" />
        <rect x="4.69922" y="11.9736" width="1.95512" height="1.9" fill="currentColor" />
        <rect x="4.69922" y="15.4502" width="1.95512" height="1.9" fill="currentColor" />
        <rect x="8.17969" y="11.9736" width="1.95512" height="1.9" fill="currentColor" />
        <rect x="8.17969" y="15.4502" width="1.95512" height="1.9" fill="currentColor" />
        <rect x="11.6562" y="11.9736" width="1.95512" height="1.9" fill="currentColor" />
        <rect x="11.6562" y="15.4502" width="1.95512" height="1.9" fill="currentColor" />
        <rect x="15.1328" y="11.9736" width="1.95512" height="1.9" fill="currentColor" />
        <rect x="15.1328" y="15.4502" width="1.95512" height="1.9" fill="currentColor" />
    </svg>
}

export default Industry;