const Medal = (props) => {
    return <svg className={props?.className} width={props?.navBar ? "25" : "24"} height={props?.navBar ? "25" : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.59473 22.5183V16.7213C6.59473 16.316 7.06049 16.067 7.41997 16.2542C9.52337 17.35 13.4027 18.2729 17.2873 15.9028C17.6371 15.6893 18.1082 15.9297 18.1082 16.3395V22.4983C18.1082 22.8676 17.7217 23.1094 17.3896 22.948L12.7347 20.6849C12.6002 20.6196 12.4437 20.6178 12.3078 20.68L7.30298 22.9729C6.97179 23.1246 6.59473 22.8826 6.59473 22.5183Z" stroke={props?.stroke} />
        <circle cx="11.9999" cy="7.83784" r="7.33784" stroke={props?.stroke} />
        <g clipPath="url(#clip0_7309_8598)">
            <path d="M12.0541 3.24329L13.4738 6.1195L16.6487 6.58356L14.3514 8.82112L14.8935 11.9822L12.0541 10.489L9.21461 11.9822L9.75677 8.82112L7.45947 6.58356L10.6343 6.1195L12.0541 3.24329Z" stroke={props?.stroke} strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_7309_8598">
                <rect width="11.027" height="11.027" fill="white" transform="translate(6.54053 2.32434)" />
            </clipPath>
        </defs>
    </svg>
}

export default Medal;