const Group = (props) => {
    return <svg className={props?.className} xmlns="http://www.w3.org/2000/svg" width={props?.size} height={props?.size} viewBox="0 0 22 23" fill="none">
        <path d="M16.7673 17.65H5.37899C5.37899 17.65 5.28776 13.1711 11.0275 13.1709C16.7673 13.1707 16.7673 17.65 16.7673 17.65Z" fill="currentColor" />
        <path d="M4.67249 17.6492C4.67249 16.9057 4.9764 15.7437 5.30585 15.1636L5.8446 14.3098C1.95872 14.065 0.804555 15.8416 0.804688 17.6492H4.67249Z" fill="currentColor" />
        <path d="M17.4415 17.6488C17.5601 16.9566 17.0197 15.7432 16.6902 15.1631L16.1298 14.2871C20.0157 14.0422 21.1915 15.8412 21.1914 17.6488H17.4415Z" fill="currentColor" />
        <ellipse cx="10.9867" cy="9.29551" rx="2.9633" ry="2.97324" fill="currentColor" />
        <circle cx="4.69141" cy="11.1025" r="2.25" fill="currentColor" />
        <circle cx="16.7656" cy="11.1025" r="2.25" fill="currentColor" />
    </svg>
}

export default Group;