import moment from 'moment';

// color list for ignite over time chart
export const igniteOverTimeChartColorList = [
    "#E31069",
    "#088AB2",
    "#7839EE",
    "#EC4A0A",
    "#BA24D5",
    "#008DEB",
    "#155EEF",
    "#CA8504",
    "#099250",
    "#FC7400"
]

/**
    * @function lazyRetry
    * @params componentImport
    * @description used to retry loading a chunk to avoid chunk load error for out of date code
*/
export const lazyRetry = (componentImport) => {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
        );
        // try to import the component
        componentImport().then((component) => {
            window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) { // not been refreshed yet
                window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh
        });
    });
}

/**
    * @function passwordStrength
    * @params password
    * @description calculate password strength
*/
export const passwordStrength = (password) => {
    if (password) {
        let score = [/[$@$!%*#?&]/, /[A-Z]/, /[0-9]/, /[a-z]/]?.reduce((item, test) => item + test?.test(password), 0);

        // Length must be at least 8 chars
        if (password?.length < 8) {
            score--;
        }
        return score;
    } else {
        return null;
    }
}

/**
    * @function getNameFromSlug
    * @params str
    * @description used to get name from slug
*/
export const getNameFromSlug = (str) => {
    if (str) {
        return str?.replaceAll('-', ' ')?.replaceAll(/\bn\b/g, '&')?.replaceAll(/\(\)/g, '')?.replaceAll(/[^a-z0-9 &|]/g, '')?.replace(/\s+/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase())?.trim();
    } else {
        return ''
    }
}

/**
    * @function getSlug
    * @params str
    * @description used to get slug
*/
export const getSlug = (str) => {
    if (str) {
        return str?.replaceAll(' ', '-')?.toLowerCase()?.replaceAll(/\|\/\//g, '-')?.replaceAll(/\s+/g, '-')?.replaceAll(/&/g, 'n')?.replaceAll(/[^a-z0-9n+\-()|]/g, '-');
    } else {
        return '';
    }
}

/**
    * @function convertNumber
    * @params n
    * @description convert the number into short form
*/
export const convertNumber = (n) => {
    if (n >= 0) {
        if (n < 1e3) {
            return n;
        } else if (n >= 1e3 && n < 1e6) {
            return +(n / 1e3).toFixed(1) + "K";
        } else if (n >= 1e6 && n < 1e9) {
            return +(n / 1e6).toFixed(1) + "M";
        } else if (n >= 1e9 && n < 1e12) {
            return +(n / 1e9).toFixed(1) + "B";
        } else if (n >= 1e12) {
            return +(n / 1e12).toFixed(1) + "T";
        } else {
            return n;
        }
    } else {
        return ''
    }
}

/**
    * @function range
    * @params start, stop, step
    * @description for getting the range of arrays in specific range with step
*/
export const range = (start, stop, step) => {
    if (typeof stop == 'undefined') {
        // one param defined
        stop = start;
        start = 0;
    }

    if (typeof step == 'undefined') {
        step = 1;
    }

    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return [];
    }

    var result = [];
    for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
        result.push(i);
    }

    return result;
}

/**
    * @function getLastWeekDate
    * @params
    * @description used to get last week date
*/
export const getLastWeekDate = () => {
    let dates = [];
    const NUM_OF_DAYS = 7;

    for (let i = 0; i < NUM_OF_DAYS; i++) {
        dates.push(moment().subtract(6 - i, 'day').format('DD/MM'));
    }
    return dates;
}

/**
    * @function getDateRange
    * @params
    * @description used to get date range between two date
*/
export const getDateRange = (firstDate, lastDate) => {
    if (moment(firstDate, 'YYYY-MM-DD').isSame(moment(lastDate, 'YYYY-MM-DD'), 'day')) {
        return [moment(lastDate).format('DD/MM')];
    }
    let date = firstDate;
    const dates = [moment(date).format('DD/MM')];
    do {
        date = moment(date).add(1, 'day');
        dates.push(date.format('DD/MM'));
    } while (moment(date).isBefore(lastDate));
    return dates;
}

/**
    * @function getDateRangeForPeriod
    * @params period, watchDateRange
    * @description used to get date range for period
*/
export const getDateRangeForPeriod = (period, watchDateRange) => {
    if (watchDateRange?.every((item) => item)) {
        return {
            startDate: moment(watchDateRange[0]).format("YYYY-MM-DD"),
            endDate: moment(watchDateRange[1]).format("YYYY-MM-DD")
        };
    } else {
        switch (period) {
            case "daily":
                return {
                    startDate: moment().format("YYYY-MM-DD"),
                    endDate: moment().format("YYYY-MM-DD")
                };
            case "weekly":
                return {
                    startDate: moment().subtract(6, 'days').format("YYYY-MM-DD"),
                    endDate: moment().format("YYYY-MM-DD")
                };
            case "monthly":
                return {
                    startDate: moment().startOf('month').format("YYYY-MM-DD"),
                    endDate: moment().format("YYYY-MM-DD")
                };
            case "yearly":
                return {
                    startDate: moment().subtract(1, 'years').add(1, 'month').format("YYYY-MM-DD"),
                    endDate: moment().format("YYYY-MM-DD")
                };
            default:
                return {
                    startDate: null,
                    endDate: null
                };
        }
    }
};

/**
    * @function getMonthRange
    * @params
    * @description used to get month range between two date
*/
export const getMonthRange = (firstDate, lastDate) => {
    let startDate = moment(firstDate, "YYYY-M-DD");
    let endDate = moment(lastDate, "YYYY-M-DD").endOf("month");

    let allMonthsInPeriod = [];

    while (startDate.isBefore(endDate)) {
        allMonthsInPeriod.push(startDate.format("YYYY-MM"));
        startDate = startDate.add(1, "month");
    };
    return allMonthsInPeriod;
}

/**
    * @function createDatasetForPeriod
    * @params period, watchDateRange, color, label, filterData
    * @description used to create dataset for period
*/
export const createDatasetForPeriod = (period, watchDateRange, color, label, filterData) => {
    let labels = [];
    let data = [];
    let ignites = [];
    let unignites = [];
    let datasets = {};

    switch (period) {
        case "daily":
            labels = [...Array(24).keys()].map(item => moment(item, 'H').format("HH:mm"));
            data = filterData ? [...Array(24).keys()].map(i => filterData?.some(item => parseInt(item?.period) === i + 1) ? Math.abs(filterData?.find(item => parseInt(item?.period) === i + 1)?.ignites - filterData?.find(item => parseInt(item?.period) === i + 1)?.unignites) : 0) : Array(24).fill(0);
            ignites = filterData ? [...Array(24).keys()].map(i => filterData?.some(item => parseInt(item?.period) === i + 1) ? filterData?.find(item => parseInt(item?.period) === i + 1)?.ignites : 0) : Array(24).fill(0);
            unignites = filterData ? [...Array(24).keys()].map(i => filterData?.some(item => parseInt(item?.period) === i + 1) ? filterData?.find(item => parseInt(item?.period) === i + 1)?.unignites : 0) : Array(24).fill(0);
            break;
        case "weekly":
            if (watchDateRange?.every((item) => item)) {
                labels = getDateRange(watchDateRange[0], watchDateRange[1]);
                data = filterData ? getDateRange(watchDateRange[0], watchDateRange[1])?.map(i => moment(i, 'DD/MM').format('D')).map(i => filterData?.some(item => parseInt(item?.period) === parseInt(i)) ? Math.abs(filterData?.find(item => parseInt(item?.period) === parseInt(i))?.ignites - filterData?.find(item => parseInt(item?.period) === parseInt(i))?.unignites) : 0) : Array(labels.length).fill(0);
                ignites = filterData ? getDateRange(watchDateRange[0], watchDateRange[1])?.map(i => moment(i, 'DD/MM').format('D')).map(i => filterData?.some(item => parseInt(item?.period) === parseInt(i)) ? filterData?.find(item => parseInt(item?.period) === parseInt(i))?.ignites : 0) : Array(labels.length).fill(0);
                unignites = filterData ? getDateRange(watchDateRange[0], watchDateRange[1])?.map(i => moment(i, 'DD/MM').format('D')).map(i => filterData?.some(item => parseInt(item?.period) === parseInt(i)) ? filterData?.find(item => parseInt(item?.period) === parseInt(i))?.unignites : 0) : Array(labels.length).fill(0);
            } else {
                labels = getLastWeekDate();
                data = filterData ? getLastWeekDate()?.map(i => moment(i, 'DD/MM').format('D')).map(i => filterData?.some(item => parseInt(item?.period) === parseInt(i)) ? Math.abs(filterData?.find(item => parseInt(item?.period) === parseInt(i))?.ignites - filterData?.find(item => parseInt(item?.period) === parseInt(i))?.unignites) : 0) : Array(7).fill(0);
                ignites = filterData ? getLastWeekDate()?.map(i => moment(i, 'DD/MM').format('D')).map(i => filterData?.some(item => parseInt(item?.period) === parseInt(i)) ? filterData?.find(item => parseInt(item?.period) === parseInt(i))?.ignites : 0) : Array(7).fill(0);
                unignites = filterData ? getLastWeekDate()?.map(i => moment(i, 'DD/MM').format('D')).map(i => filterData?.some(item => parseInt(item?.period) === parseInt(i)) ? filterData?.find(item => parseInt(item?.period) === parseInt(i))?.unignites : 0) : Array(7).fill(0);
            }
            break;
        case "monthly":
            if (watchDateRange?.every((item) => item)) {
                labels = getDateRange(watchDateRange[0], watchDateRange[1]);
                data = filterData ? getDateRange(watchDateRange[0], watchDateRange[1])?.map(i => moment(i, 'DD/MM').format('D')).map(i => filterData?.some(item => parseInt(item?.period) === i + 1) ? Math.abs(filterData?.find(item => parseInt(item?.period) === i + 1)?.ignites - filterData?.find(item => parseInt(item?.period) === i + 1)?.unignites) : 0) : Array(labels.length).fill(0);
                ignites = filterData ? getDateRange(watchDateRange[0], watchDateRange[1])?.map(i => moment(i, 'DD/MM').format('D')).map(i => filterData?.some(item => parseInt(item?.period) === i + 1) ? filterData?.find(item => parseInt(item?.period) === i + 1)?.ignites : 0) : Array(labels.length).fill(0);
                unignites = filterData ? getDateRange(watchDateRange[0], watchDateRange[1])?.map(i => moment(i, 'DD/MM').format('D')).map(i => filterData?.some(item => parseInt(item?.period) === i + 1) ? filterData?.find(item => parseInt(item?.period) === i + 1)?.unignites : 0) : Array(labels.length).fill(0);
            } else {
                labels = new Array(moment().daysInMonth()).fill(null).map((x, i) => moment().startOf('month').add(i, 'days')?.format("DD/MM"));
                data = filterData ? [...Array(moment().daysInMonth()).keys()].map(i => filterData?.some(item => parseInt(item?.period) === i + 1) ? Math.abs(filterData?.find(item => parseInt(item?.period) === i + 1)?.ignites - filterData?.find(item => parseInt(item?.period) === i + 1)?.unignites) : 0) : Array(moment().daysInMonth()).fill(0);
                ignites = filterData ? [...Array(moment().daysInMonth()).keys()].map(i => filterData?.some(item => parseInt(item?.period) === i + 1) ? filterData?.find(item => parseInt(item?.period) === i + 1)?.ignites : 0) : Array(moment().daysInMonth()).fill(0);
                unignites = filterData ? [...Array(moment().daysInMonth()).keys()].map(i => filterData?.some(item => parseInt(item?.period) === i + 1) ? filterData?.find(item => parseInt(item?.period) === i + 1)?.unignites : 0) : Array(moment().daysInMonth()).fill(0);
            }
            break;
        case "yearly":
            if (watchDateRange?.every((item) => item)) {
                labels = getMonthRange(watchDateRange[0], watchDateRange[1]).map(i => moment(i, 'YYYY-MM').format('MMM, YY'));
                data = filterData ? getMonthRange(watchDateRange[0], watchDateRange[1])?.map(i => moment(i, 'YYYY-MM').month() + 1).map(i => filterData?.some(item => parseInt(item?.period) === i) ? Math.abs(filterData?.find(item => parseInt(item?.period) === i)?.ignites - filterData?.find(item => parseInt(item?.period) === i)?.unignites) : 0) : Array(labels.length).fill(0);
                ignites = filterData ? getMonthRange(watchDateRange[0], watchDateRange[1])?.map(i => moment(i, 'YYYY-MM').month() + 1).map(i => filterData?.some(item => parseInt(item?.period) === i) ? filterData?.find(item => parseInt(item?.period) === i)?.ignites : 0) : Array(labels.length).fill(0);
                unignites = filterData ? getMonthRange(watchDateRange[0], watchDateRange[1])?.map(i => moment(i, 'YYYY-MM').month() + 1).map(i => filterData?.some(item => parseInt(item?.period) === i) ? filterData?.find(item => parseInt(item?.period) === i)?.unignites : 0) : Array(labels.length).fill(0);
            } else {
                labels = getMonthRange(moment().subtract(1, 'years').add(1, 'month'), moment()).map(i => moment(i, 'YYYY-MM').format('MMM, YY'));
                data = filterData ? getMonthRange(moment().subtract(1, 'years').add(1, 'month'), moment()).map(i => moment(i, 'YYYY-MM').month() + 1).map(i => filterData?.some(item => parseInt(item?.period) === i) ? Math.abs(filterData?.find(item => parseInt(item?.period) === i)?.ignites - filterData?.find(item => parseInt(item?.period) === i)?.unignites) : 0) : Array(12).fill(0);
                ignites = filterData ? getMonthRange(moment().subtract(1, 'years').add(1, 'month'), moment()).map(i => moment(i, 'YYYY-MM').month() + 1).map(i => filterData?.some(item => parseInt(item?.period) === i) ? filterData?.find(item => parseInt(item?.period) === i)?.ignites : 0) : Array(12).fill(0);
                unignites = filterData ? getMonthRange(moment().subtract(1, 'years').add(1, 'month'), moment()).map(i => moment(i, 'YYYY-MM').month() + 1).map(i => filterData?.some(item => parseInt(item?.period) === i) ? filterData?.find(item => parseInt(item?.period) === i)?.unignites : 0) : Array(12).fill(0);
            }
            break;
        default:
            break;
    }

    datasets = {
        label,
        data,
        pointRadius: Array(data?.length - 1)?.fill(0)?.concat(5),
        borderColor: color,
        backgroundColor: color,
        ignites,
        unignites
    };

    return {
        labels,
        datasetsForBrand: datasets
    };
}

/**
    * @function matchYoutubeUrl
    * @params url
    * @description used to check youtube url
*/
export const matchYoutubeUrl = (url) => {
    var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
        return url.match(p)[1];
    }
    return false;
}